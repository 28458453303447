import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { MarginProps } from 'styled-system';

import { Flex, Icon, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';

const BoldSpan = styled('span')(() =>
  css({
    fontWeight: 'bold',
  }),
);

export const UberFreeChargePromotionText = React.memo((props: MarginProps) => {
  const theme = useTheme();

  return (
    <Flex alignItems="center" marginTop={props.marginTop ?? theme.space.m}>
      <Icon color="blue" icon="info" size="m" marginRight={theme.space.s} />
      <Text size="s">
        キャンペーン中につき、期間中に薬の受取完了した分の当日配達利用料は<BoldSpan>無料</BoldSpan>
        となります（
        <ExternalLink url="https://intercom.help/pharms/ja/articles/9765203">
          キャンペーン詳細
        </ExternalLink>
        <Icon
          icon="blank"
          size="m"
          marginLeft={theme.space.s}
          marginRight={theme.space.s}
          color="linkBlue"
        />
        ）
      </Text>
    </Flex>
  );
});

UberFreeChargePromotionText.displayName = 'UberFreeChargePromotionText';
